import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, TextField, Button, AppBar, Toolbar, CircularProgress } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import AppointmentForm from './AppointmentForm';

const temp_appointments = [
    {
        "date": "02/10/24",
        "doctor": "Dr. Sokoya",
        "roomid": "test234"
    },
    {
        "date": "02/11/24",
        "doctor": "Tayler Mauldin",
        "roomid": "test456"
    }
]

const appointmentItemStyles = {
    border: 1,
    borderRadius: '8px',
    boxShadow: '2px 2px 4px #aaa',
    p: 2,
    mb: 2,
    bgcolor: 'background.paper',
};

async function createJanusRoom(roomName) {
    return new Promise((resolve, reject) => {
        const socket = new WebSocket('wss://janus.trl-ai.com/janus', 'janus-protocol');

        socket.onopen = function() {
            console.log("Connection established!");

            // Create a session first
            const transactionId = "create-session-" + Date.now();
            const createSessionMessage = {
                "janus": "create",
                "transaction": transactionId
            };
            socket.send(JSON.stringify(createSessionMessage));
        };

        socket.onmessage = function(event) {
            const data = JSON.parse(event.data);
            console.log("Message received: ", data);

            // Handle session creation
            if (data.janus === "success" && data.transaction.startsWith("create-session")) {
                const sessionId = data.data.id;
                console.log("Session created with ID:", sessionId);

                // Now attach to the videoroom plugin
                const transactionId = "attach-plugin-" + Date.now();
                const attachPluginMessage = {
                    "janus": "attach",
                    "plugin": "janus.plugin.videoroom",
                    "transaction": transactionId,
                    "session_id": sessionId
                };

                socket.send(JSON.stringify(attachPluginMessage));
            }

            // Handle plugin attachment
            if (data.janus === "success" && data.transaction.startsWith("attach-plugin")) {
                const sessionId = data.session_id;
                const handleId = data.data.id;  // Plugin handle ID

                console.log("Attached to plugin with handle ID:", handleId);

                // Now create the room
                const transactionId = "create-room-" + Date.now();
                const createRoomMessage = {
                    "janus": "message",
                    "body": {
                        "request": "create",
                        "room": 123,
                        "publishers": 6
                    },
                    "transaction": transactionId,
                    "session_id": sessionId,
                    "handle_id": handleId // Send handle ID to plugin
                };

                socket.send(JSON.stringify(createRoomMessage));
            }

            // Handle room creation response
            if (data.janus === "success" && data.transaction.startsWith("create-room")) {
                const janusRoomId = data.plugindata.data.room;
                console.log(`Created Janus room: ${janusRoomId}`);
                resolve(janusRoomId);  // Resolving the Promise with the room ID
            }

            // Handle any errors
            if (data.janus === "error") {
                console.error("Error from Janus:", data.error);
                reject(data.error);
            }
        };

        socket.onerror = function(error) {
            console.error("WebSocket error: ", error);
            reject(error);  // Rejecting the Promise if an error occurs
        };

        socket.onclose = function() {
            console.log("WebSocket connection closed");
        };
    });
}


const PatientProfile = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [requestApptOpen, setRequestApptOpen] = useState(false);
    const [appointments, setAppointments] = useState(temp_appointments);
    const navigate = useNavigate();

    const handleRequestApptOpen = () => {
        setRequestApptOpen(true);
    };

    const handleRequestApptClose = () => {
        setRequestApptOpen(false);
    };

    // Temp function to showcase capability
    // Will be moving the appointment modal to directly add to the appropriate mongo
    const handleAddAppointment = (newAppointment) => {
        console.log("handleAddAppointment...")
        const appointmentToAdd = {
            date: newAppointment.date, // Ensure format matches, e.g., "MM/DD/YY"
            doctor: newAppointment.doctor,
            roomid: newAppointment.roomid || "defaultRoomId" // Provide a default or derive from newAppointment
        };
        setAppointments((currentAppointments) => [...currentAppointments, appointmentToAdd]);
    };

    const updateUserRoom = async (roomId) => {
        // This function will update the user room via API
        // With this device's can get the current room their
        // user is in!!

        if (!userData) {
            console.error('User data not loaded');
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found');
            return;
        }

        try {
            const janusRoomId = await createJanusRoom(roomId);
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.userId;
            const response = await fetch(`https://scope-api.trl-ai.com/users/${userId}/update_room`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ current_room: janusRoomId })
            });

            if (!response.ok) {
                throw new Error('Failed to update current_room');
            }

            const updatedUser = await response.json();
            setUserData(updatedUser);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // TODO: Handle joining a room
    const handleJoinAppointment = async (appointment) => {
        console.log(`Join the appointment... ${appointment}`)
        const roomId = appointment.roomid;
        console.log(`Testing the roomId ${roomId}`)
        try {
            await updateUserRoom(roomId)
            navigate(`/video-session/${roomId}`)
        } catch (error) {
            console.log("Error", error)
        }
    }

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');

            if (!token) {
                // TODO: Create some good visual for this
                console.error('No token found');
                setLoading(false);
                return;
            }

            try {
                // Decode the JWT token to get the user's ID
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.userId;

                const response = await fetch(`https://scope-api.trl-ai.com/users/${userId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setUserData(data);
            } catch (error) {
                console.error('Error:', error);
                // Handle errors appropriately
            } finally {
                setLoading(false);
            }

        };

        fetchUserData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (!userData) {
        return <Typography>No user data found</Typography>;
    }

    return (
        <Grid container spacing={4} padding={2}>
            <Grid item md={3}></Grid>
            <Grid item md={3}>
                <Typography>Patient Name: {userData.name}</Typography>
                <Typography>Patient Age: {userData.age}</Typography>
                <Typography>Device ID: {userData.device_id}</Typography>
            </Grid>
            <Grid item md={3}>
                {appointments && appointments.map((appointment, index) => (
                    <Grid item key={index} md={12} sx={appointmentItemStyles}>
                        <Typography>Appointment Date: {appointment.date}</Typography>
                        <Typography>Provider: {appointment.doctor}</Typography>
                        <Button onClick={() => handleJoinAppointment(appointment)}>Join</Button>
                    </Grid>
                ))}
                <Button color="inherit" onClick={handleRequestApptOpen}>Request Appointment</Button>
            </Grid>
            <Grid item md={3}></Grid>

            {/* AppointmentForm modal */}
            <AppointmentForm open={requestApptOpen} handleClose={handleRequestApptClose} onAddAppointment={handleAddAppointment} />
        </Grid>
    );
}

export default PatientProfile;